import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../../components/icon';

const ColumnLink = ({ icon, text, to, href, method, style, badge }) => {
  const badgeElement = typeof badge !== 'undefined' ? <span className='column-link__badge'>{badge}</span> : null;
  const iconElement = () => typeof (icon) === 'function' ? icon() : <Icon id={icon} fixedWidth className='column-link__icon' />;
  if (href) {
    return (
      <a href={href} className='column-link' style={style} data-method={method}>
        {iconElement()}
        {text}
        {badgeElement}
      </a>
    );
  } else {
    return (
      <Link to={to} className='column-link' style={style}>
        {iconElement()}
        {text}
        {badgeElement}
      </Link>
    );
  }
};

ColumnLink.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  method: PropTypes.string,
  badge: PropTypes.node,
  style: PropTypes.object,
};

ColumnLink.defaultProps = {
  style: {},
};

export default ColumnLink;
