let theLocale = 'en-us';
import messages from './en.json';
import localeData from 'react-intl/locale-data/en';

export function setLocale(locale) {
  theLocale = locale;
}

export function getLocale() {
  return theLocale;
}

setLocale({ messages, localeData });
